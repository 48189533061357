<template>
  <b-card-code title="更新历史">
    <app-timeline>

      <app-timeline-item variant="danger">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h1>v2.0.0</h1>
          <small class="text-muted">2021/08/28</small>
        </div>
        <ul>
          <li>V2版本，使用新的技术与新的UI重构前端，提升用户体验</li>
          <li>使用船新的前端架构，优化了性能，统一了数据展示，解决了查询车手数据等问题</li>
          <li>数据内容与数据逻辑使用V1版本</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="info">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h3>v1.6.1</h3>
          <small class="text-muted">2019/01/24</small>
        </div>
        <ul>
          <li>增加了对F1 2018的支持</li>
          <li>完善了比赛结果页，碰撞分只显示与人的碰撞</li>
          <li>修复了一些bug</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="success">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h2>v1.6.0</h2>
          <small class="text-muted">2019/01/18</small>
        </div>
        <ul>
          <li>增加了对rFactor 2的支持（掌声，起）</li>
          <li>完善了对联赛、赛事信息中GSC、AMS、AC、rF2的判断</li>
          <li>增加了驾照分的支持（掌声，落）</li>
          <li>新增了驾照分历史的页面</li>
          <li>车手信息页增加了驾照分的数据，以及驾照分历史</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="info">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h3>v1.5.1</h3>
          <small class="text-muted">2018/12/13</small>
        </div>
        <ul>
          <li>赛事结果页明确展示是否计入安全分或者rating的赛事</li>
          <li>赛事结果页的比赛结果，优化了组别与车型的显示</li>
          <li>赛事结果页优化了赛事信息概述表</li>
          <li>赛事结果页优化了冠亚季军、杆位的信息显示</li>
          <li>赛事结果页面完善了不同组别的比赛结果分色显示</li>
          <li>赛事结果页面支持组别内计算排名和排位</li>
          <li>赛季页面修改了联赛的车手积分表，显示车手的车队名称</li>
          <li>赛季页面修复了两轮制的分站积分只显示第一轮的Bug</li>
          <li>赛季页面修复了两轮制只参加了第二轮的车手的数据Bug</li>
          <li>个人生涯信息完善了两轮制的参赛与完赛数据</li>
          <li>赛道记录页面修复了赛道记录成绩的显示问题</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="success">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h2>v1.5.0</h2>
          <small class="text-muted">2018/11/12</small>
        </div>
        <ul>
          <li>增加了对红蓝对抗赛（NPC）的积分计算方法的支持</li>
          <li>增加了针对娱乐赛等不需要计算安全分或者rating的赛事计算支持</li>
          <li>完善了积分表，对于不区分车型的联赛合并计算车手积分</li>
          <li>完善了单场比赛结果，没有车队概念，就不显示车队列</li>
          <li>修复了Pro赛照的计算bug</li>
          <li>修复了近3场安全分的计算bug</li>
          <li>首页、联赛、赛事页面中的车型显示改为组别</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="info">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h3>v1.4.1</h3>
          <small class="text-muted">2018/05/06</small>
        </div>
        <ul>
          <li>完善了联赛页面，对于不需要分组分车队计分的不予显示车队积分</li>
          <li>车队积分、车手积分，根据组别分别显示</li>
          <li>增加了车手积分、车队积分的主站插件</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="success">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h2>v1.4.0</h2>
          <small class="text-muted">2018/3/30</small>
        </div>
        <ul>
          <li>增加积分系统，包含车手积分、车队积分、车辆积分</li>
          <li>修复了Pro赛照的算法问题</li>
          <li>修复了赛照列表里信息错误的问题</li>
          <li>修复了H拖延症的问题</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="success">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h2>v1.3.0</h2>
          <small class="text-muted">2017/12/21</small>
        </div>
        <ul>
          <li>新增赛车组别”Class”体系</li>
          <li>联赛详情页面中可以查看赛车组别，对于多组别赛事进行分组显示</li>
          <li>赛事详情页面中可以看到各个车手的赛车组别</li>
          <li>车手详情的参赛记录，显示赛车组别与车辆名称</li>
          <li>赛道详情中根据每个分组显示赛道记录，并新增了赛道记录表格</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="success">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h2>v1.2.0</h2>
          <small class="text-muted">2017/11/26</small>
        </div>
        <ul>
          <li>增加了最近3场比赛安全分数据</li>
          <li>增加了车手赛照</li>
          <li>只有参加超过3场比赛，才显示HiRating</li>
          <li>修复了车手ID空格的问题</li>
          <li>优化了数据服务的性能</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="success">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h2>v1.1.0</h2>
          <small class="text-muted">2017/10/23</small>
        </div>
        <ul>
          <li>增加了对AC赛事的支持</li>
          <li>加入了娱乐赛的数据</li>
          <li>修复了赛事第二轮表头错误的bug</li>
          <li>修复了部分赛道信息错误的bug</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="info">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h3>v1.0.1</h3>
          <small class="text-muted">2017/06/24</small>
        </div>
        <ul>
          <li>增加了赛报引用的小工具</li>
        </ul>
      </app-timeline-item>

      <app-timeline-item variant="danger">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h1>v1.0.0</h1>
          <small class="text-muted">2017/06/22</small>
        </div>
        <ul>
          <li>完成V1基础版本的设计与开发，基于内测版本作出如下改进：</li>
          <li>修复了一系列UI的Bug</li>
          <li>修复了HiRating的计算算法，根据比赛日期递增计算</li>
          <li>修复了一系列的数据关联问题等功能Bug</li>
          <li>增加了算法说明</li>
        </ul>
      </app-timeline-item>

    </app-timeline>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCardCode,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
    }
  },
}
</script>
